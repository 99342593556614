@font-face {
  font-family: "Galano";
  src: local("GalanoGrotesque"), url("/static/fonts/GalanoGrotesque-Bold.woff2") format("woff2"), url("/static/fonts/GalanoGrotesque-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("/static/fonts/Poppins-Regular.woff2") format("woff2"), url("/static/fonts/Poppins-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/static/fonts/Poppins-SemiBold.woff2") format("woff2"), url("/static/fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("/static/fonts/Poppins-Bold.woff2") format("woff2"), url("/static/fonts/Poppins-Bold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  font-size: 100%;
  --color-primary: #007aff;
  --color-secondary: #41ef8b;
  --color-tertiary: #161621;
  --color-error: #ee4400;
  --color-font: #161621;
  --color-bg-primary: #fff;
  --color-bg-secondary: #f8f8f9;
  --font-primary: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --font-secondary: "Galano", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  scroll-behavior: smooth;
  margin: 0;
  background-color: #f8f8f9;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  min-width: 100%;
}

h1,
h2,
h4,
p {
  color: #313146;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1 {
  font-size: calc(36px + (49 - 36) * (100vw - 350px) / (1440 - 350));
  font-weight: 700;
  line-height: 1.3em;
}

h2 {
  font-size: calc(28px + (30 - 28) * (100vw - 400px) / (1440 - 400));
  font-weight: 800;
  line-height: 1.2em;
}

h3 {
  font-family: "Galano", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: calc(22px + (25 - 22) * (100vw - 400px) / (1440 - 400));
  line-height: 1.5em;
}

h4 {
  font-size: 12px;
  font-weight: 800;
  line-height: 1.2em;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  color: #007aff;
}

.paragraph,
p {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 1.7em;
  color: #555e80;
}

small {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  line-height: 1.7em;
  color: #555e80;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
p:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
p:last-child {
  margin-bottom: 0;
}

*::-moz-selection {
  color: #fff;
  background-color: rgb(36, 121, 255);
}

*::selection {
  color: #fff;
  background-color: rgb(36, 121, 255);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:not([type="checkbox"]) {
  appearance: none;
  border: 2px solid #c4c4c4;
  border-radius: 5px;
  background-image: none;
  background-color: #fff;
  box-shadow: none;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  padding: 0.8em 1em;
  width: 100%;
  z-index: 10;
  /*caret-color: rgb(36, 121, 255);*/
  transition: border-color 200ms ease-in;
}

input + .input__label {
  position: absolute;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  color: #8e8e8e;
  letter-spacing: 0.7px;
  left: 1.5em;
  top: 50%;
  text-transform: capitalize;
  transform: translateY(-50%);
  transition: opacity 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
  pointer-events: none;
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*:active,
*:focus {
  outline: none;
}

input:not([type="checkbox"]):active,
input:not([type="checkbox"]):active + label,
input:not([type="checkbox"]):focus,
input:not([type="checkbox"]):focus + label {
  outline: none;
  border-color: #2478ff;
  color: #2478ff;
}

::placeholder {
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.7px;
  /*color: #fff;*/
}

svg {
  fill: currentColor;
}

a.link {
  text-decoration: none;
  color: #007aff;
  font-weight: 700;
}

.cta {
  display: inline-block;
  text-align: center;
  margin: 0.7em auto;
  border: none;
  padding: 0 0.2em;
  background: transparent;
  font: inherit;
  line-height: normal;
  cursor: pointer;
  transition: all 350ms ease-in;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 800;
  /*color: --color-font;*/
  border-radius: 1px;
  text-decoration: none;
  box-shadow: inset 0 -2px 0 0 #007aff;
}

.cta:hover {
  color: #fff;
  box-shadow: inset 0 -3em 0 0 #007aff;
}
/*CTA*/
hr {
  margin: 1rem auto;
  height: 2px;
  width: 5rem;
  border: none;
  background-color: #41ef8b;
}

.two-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  align-items: center;
  max-width: 388px;
  margin: 0 auto;
  @media (max-width: 800px) {
    max-width: 100%;
  }
}

.select__label {
  pointer-events: none;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  color: #007aff;
  letter-spacing: 0.7px;
  left: 1.5em;
  text-transform: capitalize;
  z-index: 11;
}

.select__wrapper {
  position: relative;
  height: 3.75rem;
  width: 100%;
  max-width: 388px;
  margin: 0.5rem auto;
  @media (max-width: 800px) {
    max-width: 100%;
  }
}

.select__control {
  height: 100%;
  border: none;
  border-radius: 5px;
  transition: border-color 200ms ease-in !important;
  box-shadow: none !important;

  .select__dropdown-indicator {
    color: #818181 !important;
  }

  &:hover {
    border-color: #007aff !important;

    .select__dropdown-indicator {
      color: #007aff !important;
    }
  }
}

.select__placeholder {
  color: #000;
  font-weight: 700;
}

.select__value-container {
  position: relative;
  height: 100%;
  padding: 16px 40px !important;
}

.select__indicator-separator {
  display: none;
}

.select__value-container .select__option {
  position: absolute;
}

.select__placeholder {
  margin-left: 0;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.63;
}

.select__menu {
  z-index: 1000 !important;
}

.select__option {
  display: flex !important;
  align-items: center;
  font-weight: 700;
}

.select__option-icon {
  display: inline-block;
  width: 30px;
  margin-right: 1em;
  background-color: #fff;
}

.desktop-only {
  display: none;
}

.mobile-only {
  display: block;
}

.text-no-margin {
  margin: 0;
}

.custom-select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #2a7cfe;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: none;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.8em 2em 0.8em 1em;
  width: 100%;
  z-index: 10;
  transition: border-color 200ms ease-in;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' viewBox='0 0 10 6'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%232479FF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.7' d='M9 1L5 5 1 1'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:hover {
  border-color: var(--color-primary);
}

.custom-select:focus {
  color: #222;
  outline: none;
}

.custom-select option {
  font-weight: normal;
}
@media (min-width: 1400px) {
  .two-columns--responsive {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1200px) {
  .desktop-only {
    display: block;
  }

  .mobile-only {
    display: none;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, 10px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.step-lmi canvas {
  background-color: #fff;
  margin: auto;
  display: block;
  width: 100%;
  max-width: 450px;
  height: 150px;
}

.step-lmi .geosuggest {
  font-size: 1rem;
  position: relative;
  max-width: 450px;
  margin: 0 auto;
  text-align: left;
}

.step-lmi .geosuggest__input {
  width: 100%;
  padding: 0.8em 1em;
  transition: border-color 200ms ease-in;
}

.step-lmi .geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #41ef8b;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}

.step-lmi .geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
  /**
   * A geosuggest item
   */
}

.step-lmi .geosuggest__item {
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}

.step-lmi .geosuggest__item:focus,
.step-lmi .geosuggest__item:hover {
  background: #f5f5f5;
}

.step-lmi .geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.step-lmi .geosuggest__item--active:focus,
.step-lmi .geosuggest__item--active:hover {
  background: #ccc;
}

.step-lmi .geosuggest__item__matched-text {
  font-weight: bold;
}

.step-lmi .text-center {
  text-align: center;
}

.step-lmi .handle-signatue {
  display: flex;
  margin: 0 auto;
  max-width: 450px;
}
/* START STYLE MODAL*/

.modal-header {
  background-color: #007aff;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 49px;
  padding-right: 40px;
}

.modal-title {
  color: #fff;
  font-weight: 700;
}

.modal-body {
  display: flex;
}

.tab {
  flex: 1;
  padding: 62px 24px 68px 43px;
}

.tab-content {
  flex: 2;
  border-left: 1.5px solid #41ef8b;
  padding: 55px 110px 68px 64px;
  position: relative;
}

.active {
  color: #161621;
  size: 15px;
  font-weight: 600;
}

.mb-24 {
  margin-bottom: 24px;
}

.title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #161621;
}

.gray {
  color: #555e80;
  font-weight: 500;
}

.exit-modal {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  padding: 0;
}

.tab-item {
  cursor: pointer;
  margin: 10px 0;
}

.content-title,
.tab-item.active {
  color: #161621;
  size: 15px;
  font-weight: 600;
}

.gray {
  color: #555e80;
  font-weight: 500;
  margin-top: 20px;
}
/*_____*/
@media (max-width: 1024px) {
  .modal-header {
    background-color: #007aff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    height: 56px;
  }

  .modal-title {
    color: #fff;
    font-weight: 700;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    height: 80vh;
    padding: 56px 32px;
    overflow-y: scroll;
  }

  .tab {
    flex: 1;
    padding: 0 20px 0 !important;
  }

  .active {
    color: #161621;
    size: 15px;
    font-weight: 600;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #161621;
  }

  .gray {
    color: #555e80;
    font-weight: 500;
  }

  .exit-modal {
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    padding: 0;
  }

  .tab-item {
    cursor: pointer;
    position: relative;
    margin-bottom: 56px;
  }

  .line-through:after {
    top: -28px;
    left: 0;
    border: 1px solid #41ef8b;
    content: " ";
    /*height: 1;*/
    width: 100%;
    position: absolute;
    pointer-events: none;
    color: red;
  }
}

.profile-modal-paper {
  padding: 40px 20px 20px;
  border-radius: 0!important;
  font-family: "Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;

  button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.plaid-link{
  visibility:hidden;
}
/* END STYLE MODAL*/


// Consider moving the below to CSS Modules?
.step4__additional-copy {
  max-width: 388px;
  margin: 1rem auto 2rem;
  text-align: center;
}
