.btn {
  cursor: pointer;
}
.btn[disabled] {
  cursor: not-allowed;
  filter: grayscale(1);
  opacity: 0.4;
}
.btn--cta {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: var(--color-primary);
  border: none;
  padding: 1.15em 0;
  border-radius: 3px;
  margin: 14px auto;
  width: 100%;
  transition: background-color 200ms ease-in-out, box-shadow 200ms;
}
.btn--cta:not([disabled]):hover {
  background-color: #0073f2;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
}
.btn-reset,
.btn--link {
  border: none;
  outline: none;
  padding: 0;
  background: none;
  font-family: inherit;
  font-size: inherit;
}
.btn--link {
  text-decoration: none;
  color: #007aff;
  font-weight: 700;
}
.btn--primary,
.btn--secondary {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 1.2144em 2.5em;
  border: 1px solid #2479ff;
  border-radius: 3px;
}
.btn--primary {
  background: #2479ff;
  color: #fff;
}
.btn--secondary {
  background: transparent;
  color: #2479ff;
}
