.form {
  --form-color-gray: #c4c4c4;
  --form-field-height: 62px;
}
.form .form__input {
  padding: 16px 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.63;
  transition: color 200ms ease-in,border-color 200ms ease-in;
}
.form__input::placeholder {
  font-size: 16px;
  letter-spacing: normal;
  color: #818181;
}
.form__input[disabled] {
  opacity: 0.5;
  filter: grayscale(1);
}
.form__checkbox {
  height: 18px;
  width: 18px;
  margin: 0;
}
.form__checkbox:not(:checked) {
  appearance: none;
  border: 1px solid #2479ff;
}
.form__submit--is_loading:after {
  background: url(/static/images/loader.svg) center / contain no-repeat;
  background-color: inherit;
  top: 0.5em;
  bottom: 0.5em;
}

.form__dropdown,
.form__dropdown-item button {
  border-radius: 5px;
}
.form__dropdown-item:first-child button {
  padding-top: 0.75em;
}
.form__dropdown-item button {
  font: 1rem Poppins, sans-serif;
  padding: 0.75em 1em;
}
.form__dropdown-item:last-child button {
  padding-bottom: 0.75em;
}
.form__dropdown-item:hover button,
.form__dropdown-item button:focus {
  background: #2479ff;
  color: #fff;
}


/** these styles are a mess, need to overhaul legacy styles */
.form__dropdown-wrapper {
  background: none;
  border: none;
}
.form__dropdown-wrapper input {
  height: var(--form-field-height);
}

@media (max-width: 767px) {
  .form .form__input {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
