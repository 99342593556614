@import '@curiouser/react-modal/dist/index.css';

/* @curiouser/react-modal selectors */
.modal__wrapper {
  max-width: 800px;
  min-width: 320px;
}
.modal__title {
  text-align: center;
}

/* new selectors */
.modal__actions {
  display: flex;
  justify-content: center;
  margin: 1rem 0 0.5rem 0;
}
